._3U2wFq_Row {
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  display: flex;
}

._3U2wFq_Label {
  flex: 0 0 8rem;
}

._3U2wFq_Password {
  width: 12rem;
  padding: .2rem;
  font-family: sans-serif;
}

input[type="submit"]._3U2wFq_Submit {
  color: #fff;
  background-color: #0074d9;
  border-radius: .2rem;
  padding: .6rem 1.4rem;
  font-weight: 500;
}

input[type="submit"]._3U2wFq_Submit:hover, input[type="submit"]._3U2wFq_Submit:active {
  background-color: #00539b;
}

._3U2wFq_Error {
  color: #fff;
  background-color: #8c0f08;
  border-radius: .4rem;
  padding: 1rem;
}

._8S0xBW_Embed {
  width: 100%;
  height: 100%;
  border: none;
}

.Hjfufa_Map {
  width: 100%;
  height: 100%;
}

._6PAUqG_SecondHeader {
  padding: 0;
  font-size: 1.2rem;
}

._6PAUqG_Row {
  border-bottom: 1px solid #000;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

._6PAUqG_RowTop {
  border-top: 1px solid #000;
}

._6PAUqG_RowDanger {
  color: #fff;
  background-color: #dc332a;
}

._6PAUqG_Label {
  min-width: 30%;
  max-width: 30rem;
  border-left: 1px solid #000;
  padding: .5rem;
}

._6PAUqG_Value {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  flex: auto;
  padding: .5rem;
}

@media (max-width: 500px) {
  ._6PAUqG_Label {
    min-width: 5rem;
    flex: 0 0 30%;
    font-size: .85rem;
  }

  ._6PAUqG_Value {
    font-size: .85rem;
  }
}

._6PAUqG_Map {
  height: 30rem;
  width: 100%;
  max-width: 50rem;
}

._6PAUqG_OverdueHeader {
  color: #dc332a;
  text-transform: uppercase;
  margin: 1.5rem 0;
  font-size: 1.75rem;
  font-weight: bold;
}

._6PAUqG_CompletedHeader {
  color: #fff;
  background-color: #3d9970;
  border-radius: .4rem;
  margin: 1.5rem 0;
  padding: 1rem;
}

._6PAUqG_Status {
  font-weight: bold;
}

.kuRZ1q_Error {
  color: #fff;
  background-color: #8c0f08;
  border-radius: .4rem;
  padding: 1rem;
}

.kuRZ1q_Confirmation {
  color: #fff;
  background-color: #3d9970;
  border-radius: .4rem;
  padding: 1rem;
}

.bWkmRa_Form {
  margin-top: 1.5rem;
}

.bWkmRa_Row {
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  display: flex;
}

.bWkmRa_Label {
  flex: 0 0 8rem;
}

@media (max-width: 500px) {
  .bWkmRa_Label {
    flex: 0 0 5rem;
    margin-right: .5rem;
    font-size: .8rem;
  }
}

.bWkmRa_LabelTop {
  align-self: flex-start;
}

.bWkmRa_DatePicker, .bWkmRa_Text {
  width: 12rem;
  padding: .2rem .5rem;
  font-family: sans-serif;
}

.bWkmRa_TextArea {
  width: 12rem;
  height: 5rem;
  padding: .2rem .5rem;
  font-family: sans-serif;
}

.bWkmRa_Divider {
  width: 100%;
  border: none;
  border-top: 1px solid #000;
  margin: .5rem 0;
}

.bWkmRa_StatusDropdown {
  width: 12rem;
  padding: .2rem .5rem;
}

@media (max-width: 500px) {
  .bWkmRa_DatePicker, .bWkmRa_Text, .bWkmRa_TextArea, .bWkmRa_StatusDropdown {
    width: 100%;
  }
}

input[type="submit"].bWkmRa_Submit, .bWkmRa_Complete, .bWkmRa_ShareButton {
  color: #fff;
  background-color: #0074d9;
  border-radius: .2rem;
  margin-top: 1rem;
  padding: .6rem 1.4rem;
  font-weight: 500;
}

input[type="submit"].bWkmRa_Submit:hover, input[type="submit"].bWkmRa_Submit:active {
  background-color: #00539b;
}

.bWkmRa_Complete {
  background-color: #ce0d03;
  margin-left: 2rem;
}

.bWkmRa_Complete:hover, .bWkmRa_Complete:active {
  background-color: #8c0f08;
}

.bWkmRa_Preview {
  width: 100%;
  max-width: 65rem;
  height: 30rem;
  margin-top: 1rem;
}

.bWkmRa_ShareButton {
  background-color: #27ae60;
  margin-left: 2rem;
}

.bWkmRa_ShareButton:hover, .bWkmRa_ShareButton.bWkmRa_active {
  background-color: #218b4d;
}

.l6VToW_Row {
  justify-content: center;
  align-items: center;
  display: flex;
}

.l6VToW_ChangeFile {
  align-self: flex-end;
  margin-left: 1rem;
  text-decoration: underline;
}

.l6VToW_ChangeFile:hover, .l6VToW_ChangeFile:active {
  text-decoration: none;
}

html, body, #app {
  margin: 0;
  padding: 0;
}

#app {
  margin: 1.5rem;
  font-family: sans-serif;
}

h1.Title {
  padding: 0;
  font-size: 1.4rem;
}

button, input[type="submit"] {
  width: auto;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
}

/*# sourceMappingURL=index.f88bf4f6.css.map */
