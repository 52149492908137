html, body, #app {
  margin: 0;
  padding: 0;
}

#app {
  margin: 1.5rem;
  font-family: sans-serif;
}

h1.Title {
  padding: 0;
  font-size: 1.4rem;
}

button, input[type=submit] {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
}